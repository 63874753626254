import { createSlice, createSelector } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		userDetails: null,
		userCart: [],
		userAddress: {},
		userWishlist: [],
		currentOrderID: '',
		userRedirectedURL: '/',
	},
	reducers: {
		setUserDetails: (state, action) => ({
			...state,
			userDetails: { ...action.payload },
		}),
		setUserCart: (state, action) => ({
			...state,
			userCart: [...action.payload],
		}),
		setUserAddress: (state, action) => ({
			...state,
			userAddress: { ...action.payload },
		}),
		setUserWishlist: (state, action) => ({
			...state,
			userWishlist: [...action.payload],
		}),
		setCurrentOrderID: (state, action) => ({
			...state,
			currentOrderID: action.payload,
		}),
		setUserRedirectedURL: (state, action) => ({
			...state,
			userRedirectedURL: action.payload,
		}),
	},
});

const selectUser = (state) => state.user;

export const selectUserDetails = createSelector(
	[selectUser],
	(user) => user.userDetails,
);

export const selectUserCart = createSelector(
	[selectUser],
	(user) => user.userCart,
);

export const selectUserAddress = createSelector(
	[selectUser],
	(user) => user.userAddress,
);

export const selectUserWishlist = createSelector(
	[selectUser],
	(user) => user.userWishlist,
);

export const selectCurrentOrderID = createSelector(
	[selectUser],
	(user) => user.currentOrderID,
);

export const selectUserRedirectedURL = createSelector(
	[selectUser],
	(user) => user.userRedirectedURL,
);

export const {
	setUserDetails,
	setUserCart,
	setUserAddress,
	setUserWishlist,
	setCurrentOrderID,
	setUserRedirectedURL,
} = userSlice.actions;

export default userSlice.reducer;
