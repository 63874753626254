export const VERSION = 'v1.0.9';
// export const BACKEND_URL = 'http://localhost:6060';
export const S3_BUCKET_URL =
	'https://saarav-creations-s3.s3.ap-south-1.amazonaws.com';
// export const RAZORPAY_ID_KEY = 'rzp_test_syn3k0c7m99gAr';

// Prod
export const RAZORPAY_ID_KEY = 'rzp_live_gbumCyGPUhXfa8';
export const BACKEND_URL = 'https://saaravcreations.com';

export const festivalCategoryFlag = true;
export const selectedFestivalCategory = 'Raksha Bandhan';
export const festivals = [
	{ title: 'Raksha Bandhan' },
	// { title: 'Diwali' },
	// { title: 'Holi' },
	// { title: 'Navratri' },
	// { title: 'Dussehra' },
	// { title: 'Ganesh Chaturthi' },

	// { title: 'Janmashtami' },
	// { title: 'Makar Sankranti' },
	// { title: 'Maha Shivaratri' },
	// { title: 'Ugadi' },
];
