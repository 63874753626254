import React, {
	useEffect,
	useState,
	createContext,
	useCallback,
	useMemo,
} from 'react';

import axios from 'axios';
import { useDispatch } from 'react-redux';

import { BACKEND_URL } from '../utils/config';
import { setUserDetails } from '../app/slice/userSlice';

const AuthContext = createContext();

axios.defaults.withCredentials = true;

function AuthContextProvider({ children }) {
	const [loggedIn, setLoggedIn] = useState(null);
	const [user, setUser] = useState(null);
	const dispatch = useDispatch();

	const checkLoginState = useCallback(async () => {
		try {
			const response = await axios.get(`${BACKEND_URL}/api/auth/logged_in`, {
				withCredentials: true,
			});
			console.log('🚀 ~ checkLoginState ~ response:', response?.data.loggedIn);

			setLoggedIn(response?.data.loggedIn);
			localStorage.setItem('IS_LOGGED_IN', true);
			setUser({ ...response?.data.user, token: response?.data.token });
			dispatch(
				setUserDetails({ ...response?.data.user, token: response?.data.token }),
			);
		} catch (err) {
			console.error(err);
		}
	}, [dispatch]);

	useEffect(() => {
		console.log('🚀 ~ useEffect ~ checkLoginState:');

		checkLoginState();
	}, [checkLoginState]);

	const contextValue = useMemo(
		() => ({ loggedIn, checkLoginState, user }),
		[loggedIn, checkLoginState, user],
	);

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
}

export { AuthContext, AuthContextProvider };
