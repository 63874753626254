import React, { useState } from 'react';
import {
	Box,
	Typography,
	Link,
	Alert,
	Button,
	Snackbar,
	TextField,
	Container,
} from '@mui/material';
import './Footer.css';
import Axios from 'axios';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { BACKEND_URL } from '../utils/config';
import Logo from '../assets/images/website-logo1.png';
import '../styles/responsive.css';

function NewsLetter() {
	const [email, setEmail] = useState('');
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handleSubscribe = async () => {
		try {
			await Axios.post(`${BACKEND_URL}/api/newsletter`, { email });

			setEmail('');
			setOpenSnackbar(true);
		} catch (error) {
			console.error('Error subscribing:', error);
		}
	};

	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	return (
		<Box
			sx={{
				px: 8,
				py: 8,
				background: '#DBDFAA',
				display: 'flex',
				justifyContent: 'center',
				gap: '20px',
				alignItems: 'center',
			}}
			className="footer--newsletter"
		>
			<Typography
				textAlign="center"
				variant="h6"
				sx={{
					color: '#186F65',
					fontSize: '18px',
					fontWeight: 500,
				}}
				className="footer--newsletter__title"
			>
				Get in touch with us
			</Typography>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}
				className="footer--newsletter__form"
			>
				<Box>
					<TextField
						id="outlined-basic"
						label="Enter your email address"
						variant="filled"
						sx={{
							height: '24px',
							width: '400px',
							color: '#DBDFAA',
						}}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="subscribe--input footer--newsletter__formemail"
					/>
				</Box>
				<Box>
					<Button
						variant="contained"
						size="large"
						sx={{
							background: '#186F65',
							height: '55px',
							color: '#DBDFAA',
							fontSize: '14px',
							border: '1px solid rgb(24, 111, 101)',
							'&:hover': {
								color: '#186F65',
								background: 'white',
								border: '1px solid #186F65',
							},
						}}
						onClick={handleSubscribe}
						className="footer--newsletter__formbutton"
					>
						Subscribe
					</Button>
				</Box>
			</Box>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity="success"
					sx={{ width: '100%' }}
				>
					Subscription successful !
				</Alert>
			</Snackbar>
		</Box>
	);
}

function Footer() {
	return (
		<>
			<NewsLetter />
			<Container maxWidth="xl">
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '60px',
						py: 8,
					}}
					className="footer--sitemap"
				>
					<Box
						sx={{
							display: 'flex',
							flex: 2,
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '20px',
						}}
						className="footer--sitemap__info"
					>
						{/* <Typography
							variant="h6"
							noWrap
							component="div"
							sx={{
								mr: 2,
								display: { xs: 'none', md: 'flex' },
								fontFamily: 'monospace',
								fontWeight: 500,
								letterSpacing: '2px',
								width: 'fit-content',
								textTransform: 'uppercase',
								fontSize: '14px',
								background: '#DBDFAA',
								padding: '6px 12px',
								color: '#186523',
								textDecoration: 'none',
							}}
						>
							Saarav Creations
						</Typography> */}
						<Box>
							<img
								src={Logo}
								alt="site logo"
								width="120px"
								style={{ marginTop: '20px' }}
							/>
						</Box>
						<Typography
							variant="h6"
							sx={{ fontSize: '14px', fontWeight: 400, pt: 2 }}
						>
							At Saarav Creations, we craft and curate exquisite gifts,
							including Chocolate Boxes, Chocolate Bouquets, Personalized
							Frames, Scrapbooks, and Dry Fruit Boxes. Our commitment to quality
							and personalized service ensures every gift is a memorable
							experience. Celebrate life`s special moments with Saarav
							Creations.
						</Typography>
					</Box>

					<Box
						sx={{ display: 'flex', flex: 3 }}
						className="footer--sitemap__pagewrapper"
					>
						<Box
							sx={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',
								alignItems: 'center',
							}}
							className="footer--sitemap__page"
						>
							<Box
								sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
							>
								<Link
									href="/aboutus"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									About us
								</Link>

								<Link
									href="/blogs"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									Blogs
								</Link>
								<Link
									href="/contact"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									Contact Us
								</Link>
							</Box>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',

								alignItems: 'center',
							}}
							className="footer--sitemap__imppage"
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '10px',
									paddingLeft: '35px',
								}}
							>
								<Link
									href="/privacy-policy"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									Privacy Policy
								</Link>
								<Link
									href="/shipping-policy"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									Shipping Policy
								</Link>
								<Link
									href="/refund-policy"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									Refund & Cancellation Policy
								</Link>

								<Link
									href="/termsconditions"
									underline="hover"
									sx={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
								>
									Terms & Conditions
								</Link>
							</Box>
						</Box>

						<Box
							sx={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',
								alignItems: 'center',
							}}
							className="footer--sitemap__social"
						>
							<Typography
								variant="h6"
								sx={{ fontSize: '14px', fontWeight: 700 }}
							>
								Follow Us
							</Typography>
							<Box sx={{ display: 'flex' }}>
								<IconButton
									color="primary"
									aria-label="Instagram"
									href="https://www.instagram.com/saaravcreations/"
									target="_blank"
									rel="noopener noreferrer"
									sx={{ color: '#186F65' }}
								>
									<InstagramIcon />
								</IconButton>

								{/* <IconButton
									color="primary"
									aria-label="Facebook"
									href="#"
									target="_blank"
									rel="noopener noreferrer"
									sx={{ color: '#186F65' }}
								>
									<FacebookIcon />
								</IconButton> */}

								<IconButton
									color="primary"
									aria-label="WhatsApp"
									href="https://wa.me/message/UYUIGZRTSV5HB1"
									target="_blank"
									rel="noopener noreferrer"
									sx={{ color: '#186F65' }}
								>
									<WhatsAppIcon />
								</IconButton>
							</Box>
						</Box>
					</Box>
				</Box>
			</Container>
			<Box sx={{ px: 2, py: 2, background: '#186F65' }}>
				<Typography
					variant="h6"
					sx={{
						fontSize: '12px',
						fontWeight: 500,
						textAlign: 'center',
						color: 'white',
					}}
				>
					Copyright ©2023 Saarav Creations. All Rights Reserved.
				</Typography>
			</Box>
		</>
	);
}

export default Footer;
