import React from 'react';
import { Typography, Container } from '@mui/material';

function NotFound() {
	return (
		<Container sx={{ minHeight: '500px' }}>
			<Typography variant="h6" gutterBottom sx={{ fontSize: '20px', pt: 3 }}>
				404 Not Found
			</Typography>
			<Typography variant="body1">
				Sorry, the page you are looking for does not exist.
			</Typography>
		</Container>
	);
}

export default NotFound;
