import React, { useState, useEffect } from 'react';
import {
	AppBar,
	Box,
	// Fade,
	Toolbar,
	Typography,
	Drawer,
	Divider,
	Badge,
	// Avatar,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';

import MenuIcon from '@mui/icons-material/Menu';
// import PersonSharpIcon from '@mui/icons-material/PersonSharp';
// import axios from 'axios';
import { useSelector } from 'react-redux';
import {
	selectUserCart,
	// selectUserDetails,
	// setUserCart,
	// setUserDetails,
	// setUserWishlist,
} from '../app/slice/userSlice';
import '../styles/responsive.css';
import Logo from '../assets/images/website-logo1.png';
// import { BACKEND_URL } from '../utils/config';
// import { AuthContext } from '../hooks/authContext';

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Header() {
	const navigate = useNavigate();
	// const dispatch = useDispatch();
	// const userDetails = useSelector(selectUserDetails);

	const [anchorElUser, setAnchorElUser] = useState(null);

	// const { loggedIn, checkLoginState } = useContext(AuthContext);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const handleDrawerOpen = () => {
		setIsDrawerOpen(true);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	// const [anchorEl, setAnchorEl] = React.useState(null);
	// const open = Boolean(anchorEl);

	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	// const handleProfileClick = () => {
	// 	localStorage.setItem('redirectTo', '/profile');
	// 	handleClose();
	// 	navigate('/profile');
	// };

	// const handleOrdersClick = () => {
	// 	localStorage.setItem('redirectTo', '/orders');
	// 	handleClose();
	// 	navigate('/orders');
	// };

	// const handleMenuOpen = (event) => {
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handleMenuClose = () => {
	// 	setAnchorEl(null);
	// };

	// const handleNavigation2 = (path) => {
	// 	localStorage.setItem('redirectTo', path);
	// 	handleMenuClose();
	// 	navigate(path);
	// };

	// const handleLogoutClick = async () => {
	// 	dispatch(setUserDetails(null));
	// 	dispatch(setUserCart([]));
	// 	dispatch(setUserWishlist([]));
	// 	handleMenuClose();
	// 	try {
	// 		await axios.post(`${BACKEND_URL}/api/auth/logout`);
	// 		checkLoginState();
	// 	} catch (err) {
	// 		console.error('Error during logout:', err);
	// 	}
	// 	navigate('/');
	// };

	const cartData = useSelector(selectUserCart);
	const [navigateTo, setNavigateTo] = useState(null);

	useEffect(() => {
		if (!isDrawerOpen && navigateTo) {
			navigate(navigateTo);
			setNavigateTo(null);
		}
	}, [isDrawerOpen, navigateTo, navigate]);

	const handleNavigation = (path) => {
		setIsDrawerOpen(false);
		setNavigateTo(path);
	};

	return (
		<Box>
			<Drawer
				anchor="left"
				open={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						margin: '15px 0px',
					}}
				>
					<img src={Logo} alt="site logo" width="100px" />
				</Box>

				<Divider />
				<Box
					sx={{
						flexGrow: 1,
						display: 'flex',
						alignItems: 'flex-start',
						flexDirection: 'column',
						gap: '10px',
						pl: 4,
						width: '60vw',
						mt: 2,
					}}
				>
					<Button
						onClick={() => handleNavigation('/')}
						sx={{
							color: '#186F65',
							fontWeight: 400,
							textTransform: 'capitalize',
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						Home
					</Button>
					<Button
						onClick={() => handleNavigation('/aboutus')}
						sx={{
							color: '#186F65',
							fontWeight: 400,
							textTransform: 'capitalize',
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						About Us
					</Button>
					<Button
						onClick={() => handleNavigation('/product')}
						sx={{
							color: '#186F65',
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
							fontWeight: 400,
							textTransform: 'capitalize',
						}}
					>
						Our Products
					</Button>
					<Button
						onClick={() => handleNavigation('/blogs')}
						sx={{
							color: '#186F65',
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
							fontWeight: 400,
							textTransform: 'capitalize',
						}}
					>
						Blogs
					</Button>
					<Button
						onClick={() => handleNavigation('/contact')}
						sx={{
							color: '#186F65',
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
							fontWeight: 400,
							textTransform: 'capitalize',
						}}
					>
						Contact Us
					</Button>
				</Box>
			</Drawer>
			<AppBar
				position="static"
				color="primary"
				sx={{
					'& .MuiToolbar-root': { minHeight: '40px', background: '#186F65' },
				}}
			>
				<Toolbar>
					<Typography
						variant="subtitle2"
						component="div"
						sx={{ flexGrow: 1, color: 'white' }}
						className="header--primary__title"
					>
						Free Shipping on Orders Over &#x20a8;.250
					</Typography>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<PhoneIcon
							sx={{ color: 'white' }}
							className="header--primary__icon"
						/>
						<Typography
							variant="subtitle2"
							sx={{ ml: 1, color: 'white' }}
							className="header--primary__title"
						>
							Call us: +91-8929824214
						</Typography>
					</div>
				</Toolbar>
			</AppBar>

			<AppBar
				position="static"
				sx={{
					px: 12,
					background: 'white',
					'& .MuiToolbar-root': { minHeight: '40px', background: 'white' },
				}}
				className="header--secondary"
			>
				<Toolbar className="header--secondary__wrapper">
					<Box className="header--secondary__wrapperfirst">
						<IconButton
							edge="start"
							color="inherit"
							aria-label="menu"
							sx={{ mr: 2, color: '#186F65', display: 'none' }}
							className="menu--icon"
							onClick={handleDrawerOpen}
						>
							<MenuIcon />
						</IconButton>
						<Box className="header--logowrappermain">
							<img src={Logo} alt="site logo" width="120px" />
						</Box>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: 'none', md: 'flex' },
							alignItems: 'center',
							gap: '10px',
							pl: 4,
						}}
					>
						<Button
							component={Link}
							to="/"
							sx={{
								my: 2,
								color: '#186F65',
								display: 'block',
								fontWeight: 400,
								textTransform: 'capitalize',
							}}
						>
							Home
						</Button>
						<Button
							component={Link}
							to="/aboutus"
							sx={{
								my: 2,
								color: '#186F65',
								display: 'block',
								fontWeight: 400,
								textTransform: 'capitalize',
							}}
						>
							About Us
						</Button>
						<Button
							component={Link}
							to="/product"
							sx={{
								my: 2,
								color: '#186F65',
								display: 'block',
								fontWeight: 400,
								textTransform: 'capitalize',
							}}
						>
							Our Products
						</Button>
						<Button
							component={Link}
							to="/blogs"
							sx={{
								my: 2,
								color: '#186F65',
								display: 'block',
								fontWeight: 400,
								textTransform: 'capitalize',
							}}
						>
							Blogs
						</Button>
						<Button
							component={Link}
							to="/contact"
							sx={{
								my: 2,
								color: '#186F65',
								display: 'block',
								fontWeight: 400,
								textTransform: 'capitalize',
							}}
						>
							Contact Us
						</Button>
					</Box>

					<Box sx={{ flexGrow: 0 }} className="header--secondary__wrapperthird">
						<Box
							sx={{ display: 'flex' }}
							className="header--secondary__innerwrapperthird"
						>
							{/* <Typography
								variant="body1"
								align="center"
								sx={{ color: '#186F65', pl: 1, fontSize: '14px' }}
							>
								{' '}
								<Button
									component={Link}
									to="/wishlist"
									sx={{
										my: 2,
										color: '#186F65',
										display: 'block',
										textTransform: 'capitalize',
										fontWeight: 400,
									}}
									className="header--secondary__innerwrapperthirdbtn"
								>
									<Badge badgeContent={0} color="error">
										<FavoriteIcon sx={{ color: '#186F65' }} />
									</Badge>

									<span className="header--secondary__wrapperthirdtitle">
										<br />
										Wishlist
									</span>
								</Button>
							</Typography> */}

							<Typography
								variant="body1"
								align="center"
								sx={{ color: '#186F65', pl: 1, fontSize: '14px' }}
							>
								{' '}
								<Button
									component={Link}
									to="/cart"
									sx={{
										my: 2,
										color: '#186F65',
										display: 'block',
										textTransform: 'capitalize',
										fontWeight: 400,
									}}
									className="header--secondary__innerwrapperthirdbtn"
								>
									<Badge badgeContent={cartData.length} color="error">
										<ShoppingCartIcon sx={{ color: '#186F65' }} />
									</Badge>

									<span className="header--secondary__wrapperthirdtitle">
										<br />
										Cart
									</span>
								</Button>
							</Typography>
							{/* {loggedIn ? (
								<Typography
									variant="body1"
									align="center"
									sx={{ color: '#186F65', pl: 1, fontSize: '14px' }}
								>
									<Button
										id="fade-button"
										aria-controls={open ? 'fade-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleMenuOpen}
										sx={{ my: 2, color: '#186F65', display: 'block' }}
										className="header--secondary__innerwrapperthirdbtn"
									>
										<Avatar />
									</Button>

									<Menu
										id="fade-menu"
										MenuListProps={{
											'aria-labelledby': 'fade-button',
										}}
										anchorEl={anchorEl}
										open={open}
										onClose={handleMenuClose}
										TransitionComponent={Fade}
										PaperProps={{
											elevation: 0,
											sx: {
												overflow: 'visible',
												filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
												mt: 1.5,
												'& .MuiAvatar-root': {
													width: 32,
													height: 32,
													ml: -0.5,
													mr: 1,
												},
												'&::before': {
													content: '""',
													display: 'block',
													position: 'absolute',
													top: 0,
													right: 14,
													width: 10,
													height: 10,
													bgcolor: 'background.paper',
													transform: 'translateY(-50%) rotate(45deg)',
													zIndex: 0,
												},
											},
										}}
										transformOrigin={{ horizontal: 'right', vertical: 'top' }}
										anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
									>
										<Typography
											sx={{
												padding: '10px 15px',
												fontSize: '14px',
												color: '#5a5a5a',
											}}
										>
											Welcome {userDetails?.firstname} {userDetails.lastname}
										</Typography>
										<Divider />
										<MenuItem
											onClick={() => {
												handleNavigation2('/profile');
												handleMenuClose();
											}}
											sx={{ fontSize: '14px' }}
										>
											My Profile
										</MenuItem>
										<MenuItem
											onClick={() => {
												handleNavigation2('/orders');
												handleMenuClose();
											}}
											sx={{ fontSize: '14px' }}
										>
											My orders
										</MenuItem>
										<Divider />
										<MenuItem
											onClick={() => {
												handleLogoutClick();
												handleMenuClose();
											}}
											sx={{ fontSize: '14px' }}
										>
											Logout
										</MenuItem>
									</Menu>
								</Typography>
							) : (
								<Typography
									variant="body1"
									align="center"
									sx={{ color: '#186F65', pl: 1, fontSize: '14px' }}
								>
									{' '}
									<Button
										onClick={() => {
											localStorage.setItem('redirectTo', '/profile');
											navigate('/login');
										}}
										sx={{
											my: 2,
											color: '#186F65',
											display: 'block',
											textTransform: 'capitalize',
											fontWeight: 400,
										}}
										className="header--secondary__innerwrapperthirdbtn"
									>
										<Badge badgeContent={0} color="error">
											<PersonSharpIcon sx={{ color: '#186F65' }} />
										</Badge>
										<span className="header--secondary__wrapperthirdtitle">
											<br /> Login
										</span>
									</Button>
								</Typography>
							)} */}
						</Box>
						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{settings.map((setting) => (
								<MenuItem key={setting} onClick={handleCloseUserMenu}>
									<Typography textAlign="center">{setting}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default Header;
