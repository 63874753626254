import { createSlice, createSelector } from '@reduxjs/toolkit';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isLogged: false,
	},
	reducers: {
		setLoggedIn: (state) => ({ ...state, isLogged: true }),
		setLoggedOut: (state) => ({ ...state, isLogged: false }),
	},
});

const selectAuth = (state) => state.auth;

export const selectIsLogged = createSelector(
	[selectAuth],
	(auth) => auth.isLogged,
);

export const { setLoggedOut, setLoggedIn } = authSlice.actions;
export default authSlice.reducer;
