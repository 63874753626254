import React, { lazy, Suspense } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import NotFound from './components/NotFound';
import { AuthContextProvider } from './hooks/authContext';

const Home = lazy(() => import('./pages/main/Home'));
const About = lazy(() => import('./pages/main/AboutUs'));
const ProductStore = lazy(() => import('./pages/main/ProductStore'));
const Blogs = lazy(() => import('./pages/main/Blogs'));
const Contact = lazy(() => import('./pages/main/ContactUs'));
const WishList = lazy(() => import('./pages/main/Wishlist'));
const Login = lazy(() => import('./pages/main/LogIn'));
const Register = lazy(() => import('./pages/main/Register'));
const SingleBlog = lazy(() => import('./pages/main/SingleBlog'));
const PrivacyPolicy = lazy(() => import('./pages/main/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./pages/main/RefundPolicy'));
const ShippingPolicy = lazy(() => import('./pages/main/ShippingPolicy'));
const TermAndConditions = lazy(() => import('./pages/main/TermAndConditions'));
const Cart = lazy(() => import('./pages/main/Cart'));
const OrderStatus = lazy(() => import('./pages/main/UserOrders'));
const Profile = lazy(() => import('./pages/main/UserProfile'));
const SingleProduct = lazy(() => import('./pages/main/SingleProduct'));
const Shipping = lazy(() => import('./pages/main/Shipping'));
const Payment = lazy(() => import('./pages/main/Payment'));
const PasswordResetPage = lazy(() => import('./pages/main/PasswordReset'));
const ForgetPasswordRequest = lazy(() =>
	import('./pages/main/ForgetPassword/ForgetPasswordRequest'),
);
const PasswordReset = lazy(() =>
	import('./pages/main/ForgetPassword/PasswordReset'),
);

const MainLayout = lazy(() => import('./pages/admin/MainLayout'));
const Dashboard = lazy(() => import('./pages/admin/Dashboard'));
const AddCategory = lazy(() => import('./pages/admin/AddCategory'));
const Enquiries = lazy(() => import('./pages/admin/Enquiries'));
const Customers = lazy(() => import('./pages/admin/Customers'));
const Addproduct = lazy(() => import('./pages/admin/Addproduct'));
const Productlist = lazy(() => import('./pages/admin/Productlist'));
const Categorylist = lazy(() => import('./pages/admin/Categorylist'));
const ManageOrders = lazy(() => import('./pages/admin/ManageOrders'));
const Couponlist = lazy(() => import('./pages/admin/Couponlist'));
const AddCoupon = lazy(() => import('./pages/admin/AddCoupon'));
const Addblog = lazy(() => import('./pages/admin/Addblog'));
const Bloglist = lazy(() => import('./pages/admin/Bloglist'));
const Addblogcat = lazy(() => import('./pages/admin/Addblogcat'));
const Blogcatlist = lazy(() => import('./pages/admin/Blogcatlist'));
const Addblogtag = lazy(() => import('./pages/admin/Addblogtag'));
const Blogtaglist = lazy(() => import('./pages/admin/Blogtaglist'));
const Adminlogin = lazy(() => import('./pages/admin/Adminlogin'));
const Adminprofile = lazy(() => import('./pages/admin/Adminprofile'));
const Newsletter = lazy(() => import('./pages/admin/Newsletter'));
const Callback = lazy(() => import('./components/Callback'));
const ConfirmationPage = lazy(() => import('./pages/main/Confirmation'));

const router = createBrowserRouter([
	{ path: '/login', element: <Login /> },
	{ path: '/register', element: <Register /> },
	{ path: '/forget-password-request', element: <ForgetPasswordRequest /> },
	{ path: '/forget-password', element: <PasswordReset /> },
	{ path: '/resetpassword', element: <PasswordResetPage /> },
	{
		path: '/',
		element: <Layout />,
		children: [
			{ path: '*', element: <NotFound /> },
			{ path: '/', element: <Home /> },
			{ path: 'aboutus', element: <About /> },
			{ path: 'profile', element: <Profile /> },
			{ path: 'blogs', element: <Blogs /> },
			{ path: 'blog/:id', element: <SingleBlog /> },
			{ path: 'product', element: <ProductStore /> },
			{ path: 'product/:id', element: <SingleProduct /> },
			{ path: 'cart', element: <Cart /> },
			{ path: 'privacy-policy', element: <PrivacyPolicy /> },
			{ path: 'refund-policy', element: <RefundPolicy /> },
			{ path: 'shipping-policy', element: <ShippingPolicy /> },
			{ path: 'termsconditions', element: <TermAndConditions /> },
			{ path: 'contact', element: <Contact /> },
			{ path: 'orders', element: <OrderStatus /> },
			{ path: 'wishlist', element: <WishList /> },
			{ path: 'shipping', element: <Shipping /> },
			{ path: 'payment', element: <Payment /> },
			{ path: 'confirmation', element: <ConfirmationPage /> },
			{
				path: '/auth/callback',
				element: <Callback />,
			},
		],
	},
	{ path: '/saarav/admin/login', element: <Adminlogin /> },
	{
		path: '/saarav/admin',
		element: <MainLayout />,
		children: [
			{ path: '', element: <Dashboard /> },
			{ path: 'profile', element: <Adminprofile /> },
			{ path: 'customers', element: <Customers /> },
			{ path: 'product', element: <Addproduct /> },
			{ path: 'list-product', element: <Productlist /> },
			{ path: 'category', element: <AddCategory /> },
			{ path: 'list-category', element: <Categorylist /> },
			{ path: 'coupon-list', element: <Couponlist /> },
			{ path: 'coupon', element: <AddCoupon /> },
			{ path: 'orders', element: <ManageOrders /> },
			{ path: 'blog', element: <Addblog /> },
			{ path: 'blog-list', element: <Bloglist /> },
			{ path: 'blog-category', element: <Addblogcat /> },
			{ path: 'blog-category-list', element: <Blogcatlist /> },
			{ path: 'blog-tag', element: <Addblogtag /> },
			{ path: 'blog-tag-list', element: <Blogtaglist /> },
			{ path: 'newsletter', element: <Newsletter /> },
			{ path: 'enquiries', element: <Enquiries /> },
		],
	},
]);

function App() {
	return (
		<div>
			<AuthContextProvider>
				<Suspense
					fallback={
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100vh',
							}}
						>
							<CircularProgress
								size={30}
								sx={{
									color: '#007465',
								}}
							/>
						</Box>
					}
				>
					<RouterProvider router={router} />
				</Suspense>
			</AuthContextProvider>
		</div>
	);
}

export default App;
